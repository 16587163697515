.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 56px;
  padding: 0;
  background: #673AB7;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.header h1 {
  float: left;
  margin: 0;
  padding: 0 15px;
  font-size: 24px;
  line-height: 56px;
  font-weight: 400;
  color: #FFF;
}

.header nav {
  float: right;
  font-size: 100%;
}

.header nav a {
  display: inline-block;
  height: 56px;
  line-height: 56px;
  padding: 0 15px;
  min-width: 50px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  text-decoration: none;
  color: #FFF;
  will-change: background-color;
}

.header nav a:hover,
.header nav a:active {
  background: rgba(0, 0, 0, 0.2);
}

.header nav a.active {
  background: rgba(0, 0, 0, 0.4);
}

.login-box {
  border-radius: 10px;
  display: flex;
  width: 100%;
  min-height: 50vh;
  place-items: center;
  margin: 2%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.item-right-topbar {
  margin-bottom: 15px !important;
}

.logo {
  margin: 10% 0;
  text-align-last: center;
}

.img-login {
  border-radius: 10px 0 0 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar {
  vertical-align: middle;
  height: auto;
  width: 60%;
  border-radius: 50%;
}

.avatar-profile {
  vertical-align: middle;
  height: auto;
  width: 4vh;
  border-radius: 50%;
  padding-left: 5px;
}

.profile-mini-box {
  cursor: pointer;
}

.img-login-box {
  width: 60vh !important;
  height: 100%;
}

.flex-login-box {
  display: flex;
  height: 100vh !important;
  width: 100%;
}

.breadcrumb-box {
  padding: 0 20px !important;
  padding-top: 20px !important;
}

.loading-center {
  max-height: 100vh !important;
  z-index: 2000 !important;
}

.div-loading-center {
  height: 100% !important;
  background-color: rgb(229, 229, 229, 0.5) !important;
}

.stripe-row-grey {
  background-color: rgb(229, 229, 229, 0.5);
}

.stripe-row-black {
  background-color: black;
}

.topbar-user {
  flex: 1;
  text-align-last: right;
  padding-right: 2px;
}

.topbar-dropdown {
  padding-top: 5px;
  padding-right: 3%;
}

.checkbox-group-vertical {
  display: block !important;
  margin-right: 0 !important;
}

.content-checkbox-group {
  display: block;
  max-height: 30vh !important;
  overflow-y: auto;
  margin-right: 16px !important;
}

.topbar-menu {
  place-self: center;
  padding-top: 15px;
  white-space: nowrap;
}

.header-topbar-menu-fixed {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 80%;
}

.header-topbar-logo {
  float: left;
  width: 120px !important;
  height: 31px !important;
  margin: 16px 24px 16px 0;
  background: red;
}

.topbar-menu-div {
  display: flex !important;
  width: 100% !important;
}

.topbar-profile-div {
  display: flex !important;
  width: 100% !important;
  flex: 1 !important;
}

.ant-menu-title-content {
  font-weight: bold;
}

@media only screen and (max-width: 680px) {

  .img-login-box,
  .item-right-topbar {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {

  .topbar-menu {
    display: none;
  }
}

.ant-menu-submenu-arrow {
  color: white !important;
}

.print-container {
  padding: 20px;
}

.invoice {
  border: 1px solid #ddd;
  padding: 20px;
  width: 80%;
  margin: auto;
  background-color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
}

.company-info {
  text-align: right;
}

.qr-code {
  width: 100px;
}

.customer-info,
.product-info {
  margin-top: 20px;
}

.print-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media print {
  .print-buttons,
  .no-print {
    display: none; 
  }

  .body-print, .body-print * {
    visibility: visible; 
  }

  @page {
    size: 6in 4in; 
    margin: 0; 
  }

  .body-print {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0; 
    padding: 0; 
    border: none;
  }

  body * {
    visibility: hidden; 
  }
} 


@font-face {
  font-family: 'Sarabun-Light';
  src: url("../assets/fonts/OTF/Sarabun-Light.ttf") format("truetype");
  font-weight: 300; 
  font-style: normal;
}

.ant-typography {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1ikt7gn).ant-table-wrapper .ant-table {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1ikt7gn).ant-menu {
  font-family: 'Sarabun-Light', sans-serif !important;
}

.ant-menu-title-content {
  font-family: 'Sarabun-Light' !important;
}

:where(.css-dev-only-do-not-override-1do2sjk).ant-btn { 
  font-family: 'Sarabun-Light' !important;
}

:where(.css-1do2sjk).ant-btn {
  font-family: 'Sarabun-Light' !important;
}

:where(.css-1ikt7gn).ant-menu {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-1ikt7gn).ant-table-wrapper .ant-table {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1ikt7gn).ant-tag { 
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-1ikt7gn).ant-tag { 
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1gzbtfj).ant-btn {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css--1gzbtfj).ant-btn {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1ikt7gn).ant-btn {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-1ikt7gn).ant-btn {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-n2tyab).ant-btn {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-n2tyab).ant-btn {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1ikt7gn).ant-breadcrumb {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-1ikt7gn).ant-breadcrumb {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1ikt7gn).ant-input {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1ikt7gn).ant-select-single .ant-select-selector {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1ikt7gn).ant-upload-wrapper {
  font-family: 'Sarabun-Light', sans-serif !important;
}

.ant-select-selection-search-input {
  font-family: 'Sarabun-Light', sans-serif !important;
}

.ant-select-selection-item {
  font-family: 'Sarabun-Light', sans-serif !important;
}

.ant-select-item-option-content {
  font-family: 'Sarabun-Light', sans-serif !important;
}

.ant-select-item .ant-select-item-option {
  font-family: 'Sarabun-Light', sans-serif !important;
}

.ant-menu-item.ant-menu-item-selected {
  color: black !important;
}

:where(.css-dev-only-do-not-override-1atm59a).ant-btn {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-oby7im).ant-btn {
  font-family: 'Sarabun-Light', sans-serif !important;
}

.ant-pagination-total-text {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1ikt7gn).ant-pagination .ant-pagination-item {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1ikt7gn).ant-layout-footer {
  font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-btn{
 font-family: 'Sarabun-Light', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1sanqn8).ant-btn {
  font-family: 'Sarabun-Light', sans-serif !important;
}

.ant-menu-item.ant-menu-item-active {
  color: #000000 !important;
}

.ant-menu-title-content {
  color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-sub {
  margin-left: 1px !important;
  background-color: #222222 !important;
}