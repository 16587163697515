@media screen {
  .for-print {
    display: none !important;
  }
  .page-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
  }
  .bill-wrapper {
    background: white;
    border: 2px solid black;
    padding: 20px;
    width: 7in;
    height: 5in;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media print {
  body * {
    visibility: hidden;
  }
  .for-screen {
    display: none !important;
  }
  .for-print {
    display: block !important;
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
  .for-print * {
    visibility: visible;
  }
  .print-page {
    width: 6in;
    height: 4in;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
    page-break-after: always;
  }
  .print-page:last-child {
    page-break-after: avoid;
  }
  .no-print {
    display: none !important;
  }
  @page {
    size: 6in 4in landscape;
    margin: 0;
  }
}